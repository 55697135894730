import { combineReducers, Reducer, AnyAction } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
export interface AsyncReducers {
    [key: string]: Reducer<any, AnyAction>;
  }
const rootReducer = (asyncReducers?:AsyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
