import fetch from '../auth/FetchInterceptor'
 interface IAuthService{
  login (data: any): Promise<any>; 
  register(data: any): Promise<any>;
  logout(): Promise<any>; 
  loginInOAuth():string;
 }
const AuthService:IAuthService = {
  
async login (data: any) {
	// const response=await fetch({
	// 	url: '/auth/login',
	// 	method: 'post',
	// 	data: data
	// })
	const response={
		name:"arslan"
	}
	console.log("here",response)

	return response;
},

register(data) {
	// let response= fetch({
	// 	url: '/auth/register',
	// 	method: 'post',
	// 	data: data
	// })
	const response=data
	return response
}
,
logout () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
},

loginInOAuth() {
	// return fetch({
	// 	url: '/auth/loginInOAuth',
	// 	method: 'post'
	// })
	const response=''
	return response
}
}

export default AuthService;