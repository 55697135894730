import React, { lazy, Suspense, memo } from 'react'
import { useSelector } from 'react-redux'
import { ConfigProvider } from 'antd'
import Loading from '../components/shared-components/Loading'
import { lightTheme, darkTheme } from '../configs/ThemeConfig'
import { resources } from '../lang'
import useBodyClass from '../utils/hooks/useBodyClass'
import Routes from '../routes'

const AppLayout = lazy(() => import('./AppLayout'))
const AuthLayout = lazy(() => import('./AuthLayout'))
interface AuthState {
    auth: {
        token: string | null
    }
    theme: {
        blankLayout: boolean | null
        locale: string | null
        direction: 'ltr' | 'rtl' | undefined
        currentTheme: string | null
    }
}

const Layouts: React.FC = () => {
    const token = useSelector((state: AuthState | undefined) => state.auth.token)
    const blankLayout = useSelector((state: AuthState | undefined) => state.theme.blankLayout)

    const Layout = token && !blankLayout ? AppLayout : AuthLayout

    const locale = useSelector((state: AuthState) => state.theme.locale)
    const direction = useSelector((state: AuthState) => state.theme.direction)
    const currentTheme = useSelector((state: AuthState) => state.theme.currentTheme)
    const currentAppLocale = resources[locale]

    useBodyClass(`dir-${direction}`)
    interface ThemeConfig {
        components: {
            Dropdown: {
                controlPaddingHorizontal: number
                controlHeight: number
                borderRadiusLG: number
            }
        }
    }
    const themeConfig: ThemeConfig = currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme }

    return (
        <ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
            <Suspense fallback={<Loading cover="content" />}>
                <Layout direction={direction}>
                    <Routes />
                </Layout>
            </Suspense>
        </ConfigProvider>
    )
}

export default memo(Layouts)
