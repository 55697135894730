import CryptoJS from "crypto-js";
import Utils from ".";

export const encriptData = (data: any) => {
    let encription = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        Utils.ENCRIPTION_KEY
    );
    return encription;
};

export const decriptData = (data: any) => {
    let bytes = CryptoJS.AES.decrypt(
        data,
        Utils.ENCRIPTION_KEY
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedData;
};

