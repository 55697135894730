import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AUTH_PREFIX_PATH, UNAUTHENTICATED_ENTRY, REDIRECT_URL_KEY } from '../configs/AppConfig'

interface IState {
    auth: any
}
const ProtectedRoute = () => {
    const location = useLocation()
    const { token } = useSelector((state: IState) => state.auth)

    if (!token) {
        return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
    }

    return <Outlet />
}

export default ProtectedRoute
