import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_TOKEN, USER_DATA } from '../../constants/AuthConstant'
import AuthService from '../../services/AuthService'
import { decriptData, encriptData } from '../../utils/helperFunctions'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) ? decriptData(localStorage.getItem(AUTH_TOKEN)) : null,
    user_data: localStorage.getItem(USER_DATA) ? decriptData(localStorage.getItem(USER_DATA)) : null,
}

interface Idata {
    email: string | null
    password: string | null
}
interface IState {
    message: any
    showMessage: boolean
    loading: boolean
    token: string
    redirect: string
}
export const signIn = createAsyncThunk('auth/login', async (data: Idata, { rejectWithValue }) => {
    const { email, password } = data
    console.log(data)
    try {
        const response = await AuthService.login({ email, password })
        console.log(response)
        const token = 'response.data.token'
        localStorage.setItem(AUTH_TOKEN, token)
        return token
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signUp = createAsyncThunk('auth/register', async (data: Idata, { rejectWithValue }) => {
    const { email, password } = data
    try {
        const response = await AuthService.register({ email, password })
        const token = 'response.data.token'
        localStorage.setItem(AUTH_TOKEN, token)
        return token
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signOut = createAsyncThunk('auth/logout', async () => {
    // const response = await FirebaseService.signOutRequest()
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(USER_DATA)
    // return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = 'response.data.token'
        localStorage.setItem(AUTH_TOKEN, token)
        return token
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = 'response.data.token'
        localStorage.setItem(AUTH_TOKEN, token)
        return token
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (state, { payload }) => {
            const { token, user_data }: any = payload
            localStorage.setItem(AUTH_TOKEN, String(encriptData(token)))
            localStorage.setItem(USER_DATA, String(encriptData(user_data)))
            state.token = token
            state.user_data = user_data
        },
        clearAuthData: (state) => {
            localStorage.removeItem(AUTH_TOKEN)
            localStorage.removeItem(USER_DATA)
            state.token = null
            state.user_data = null
        },
        setUserData: (state, { payload }) => {
            const { user_data }: any = payload
            localStorage.setItem(USER_DATA, String(encriptData(user_data)))
            state.user_data = user_data
        },
        authenticated: (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.token = action.payload
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideAuthMessage: (state) => {
            state.message = ''
            state.showMessage = false
        },
        signOutSuccess: (state) => {
            state.loading = false
            state.token = null
            state.redirect = '/'
        },
        showLoading: (state) => {
            state.loading = true
        },
        signInSuccess: (state, action) => {
            state.loading = false
            state.token = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signIn.rejected, (state: IState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signOut.fulfilled, (state: IState) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signOut.rejected, (state: IState) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signUp.pending, (state: IState) => {
                state.loading = true
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signUp.rejected, (state: IState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signInWithGoogle.pending, (state) => {
                state.loading = true
            })
            .addCase(signInWithGoogle.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signInWithGoogle.rejected, (state: IState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signInWithFacebook.pending, (state) => {
                state.loading = true
            })
            .addCase(signInWithFacebook.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signInWithFacebook.rejected, (state: IState, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
    },
})

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess, setAuthData, clearAuthData, setUserData } = authSlice.actions

export default authSlice.reducer
