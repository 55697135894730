import { baseApi } from './api-slices/baseApi'
import rootReducer from './rootReducer'
import { configureStore, combineReducers, Reducer, Store } from '@reduxjs/toolkit'

interface AsyncReducers {
    [key: string]: Reducer<any, any>
}

interface ExtendedStore extends Store {
    asyncReducers: AsyncReducers
}

const middlewares = [baseApi.middleware]

const reducers: AsyncReducers = {
    [baseApi.reducerPath]: baseApi.reducer,
}

const store = configureStore({
    reducer: rootReducer(reducers),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewares),
    devTools: process.env.NODE_ENV === 'development',
}) as ExtendedStore

store.asyncReducers = {}

export const injectReducer = (key: string, reducer: Reducer<any, any>) => {
    if (store.asyncReducers[key]) {
        return false
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(combineReducers({ ...rootReducer(), ...store.asyncReducers }))
    return store
}

export default store
